<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          label="分类名称"
          prop="cateName"
          v-bind="formItemLayout"
      >
        <a-input
            v-model="form.cateName"
            allow-clear
            placeholder="输入分类名称"
        />
      </a-form-model-item>
      <a-form-model-item label="分类类型" v-bind="formItemLayout" prop="status">
          <a-radio-group v-model="form.type" v-decorator="['type',
                   {initialValue: 1}]" :options="$DICT['categoryType']"/>
      </a-form-model-item>
      <a-form-model-item label="是否有效" v-bind="formItemLayout" prop="status">
        <a-radio-group v-model="form.status" v-decorator="['status',
                   {initialValue: 1}]">
          <a-radio :value="1">有效</a-radio>
          <a-radio :value="0">无效</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否置顶" v-bind="formItemLayout" prop="isTop">
        <a-radio-group v-model="form.isTop" v-decorator="['isTop',
                   {initialValue: 1}]">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <!--      <a-form-model-item label="首页展示" v-bind="formItemLayout" prop="indexFlag">-->
      <!--        <a-radio-group v-model="form.indexFlag" v-decorator="['indexFlag',-->
      <!--                   {initialValue: 0}]">-->
      <!--          <a-radio :value="1">是</a-radio>-->
      <!--          <a-radio :value="0">否</a-radio>-->
      <!--        </a-radio-group>-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="自救手册分类" v-bind="formItemLayout" prop="indexFlag">-->
      <!--        <a-radio-group v-model="form.manualFlag" v-decorator="['manualFlag',-->
      <!--                   {initialValue: 0}]">-->
      <!--          <a-radio :value="1">是</a-radio>-->
      <!--          <a-radio :value="0">否</a-radio>-->
      <!--        </a-radio-group>-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="排序号" v-bind="formItemLayout" prop="sortNumber">
        <a-input-number v-model="form.sortNumber" :step="10" :min="10" v-decorator="['sortNumber',
                   {initialValue: 10}]"/>
        <span style="font-size:0.8em;color:#999">数字越大排序越前！</span>
      </a-form-model-item>
      <a-form-model-item label="描述" v-bind="formItemLayout" prop="remark">
        <a-textarea v-model="form.remark" v-decorator="['remark']" style="min-height:100px;"/>
      </a-form-model-item>
      <a-form-model-item ref="doorHeader" label="上传Logo" v-bind="formItemLayout">
        <div class="clearfix">
          <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              withCredentials
              :multiple="false"
              :data="{ bucketType: 'public', uploadType: 'image' }"
              :headers="headers"
              :file-list="imageList"
              action="admin/upload/uploadImage"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="(info) => handleImageChange(info)"
          >
            <div v-if="imageList.length < 1">
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
          <!--  -->
          <a-modal
              v-drag-modal
              title="图片预览"
              :visible="previewVisible"
              :footer="null"
              @cancel="previewVisible = false"
          >
            <img alt="example" style="width: 100%" :src="previewImage"/>
          </a-modal>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {getBase64OfPreview} from '@/utils/common.js';


export default {
  name: "ModifyCategoryModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 15},
      },
      mode: 'edit',
      title: "",
      visible: false,
      confirmLoading: false,
      previewVisible: false,
      form: {},
      imageList: [],
      previewImage: "",
      rules: {
        cateName: [
          {
            required: true,
            message: "请输入分类名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    headers() {
      return {
        Authentication: this.$store.state.account.token,
      };
    },
  },
  created() {
  },
  methods: {
    handleImageChange(info) {
      this.imageList = info.fileList || [];
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      this.loading = false;
    },
    beforeUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/gif";
      if (!isJpgOrPng) {
        this.$message.error("只能支持JPEG/PNG/GIF图片!");
      }
      const isLt2M = file.size / 1024 / 1024 <= 3;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过3MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview && !file.thumbUrl) {
        file.preview = await getBase64OfPreview(file.originFileObj);
      }
      this.previewImage = file.url || file.preview || file.thumbUrl;
      this.previewVisible = true;
    },
    showModal(record, mode) {
      const that = this;

      this.visible = true;
      this.mode = mode || 'new'

      let array = [];
      if (this.mode == 'new') {
        this.title = "创建圈子分类";
        this.form = {
          isTop: 0,
          status: 1,
          indexFlag: 0
        };
      } else {
        this.title = "修改圈子分类，ID：" + record.cateId;
        this.form = {
          ...record,
        };
        let imageList = [record.logo];
        imageList.forEach((v) => {
          if (!v) return;
          let path = that.$helper.getFullImgPath(v);
          array.push({
            type: "image/png",
            name: v,
            uid: v,
            status: "done",
            thumbUrl: path,
            response: {data: path, code: 0},
          });
        });
      }
      this.imageList = array;
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          this.form.logo = this.imageList.map((item) => {
            return item.response.data;
          })[0];
          this.$postJson("category/save", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("保存成功");
            this.$emit("success");
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
